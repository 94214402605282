import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import '@/axios';  // 引入axios
import ElementUI from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css'
import global from './global'  // 引入通用参数及函数库
import './assets/css/general.css'  // 引入通用样式库
import './assets/css/animate.css'  // 引入动画样式库
import './assets/css/font-awesome-4.7.0/css/font-awesome.min.css'  // 引入Font Awesome 4.7.0图标字体库和CSS框架

Vue.use(ElementUI);

Vue.config.productionTip = false

// 绑定global（通用参数及函数库）为Vue全局变量
Vue.prototype.$global = global;

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
