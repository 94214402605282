import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
      alert: ''  // 提示内容
  },
  mutations: {
      setAlert: function(state, alert) {
          state.alert = alert;
      },
      emptyAlert: function(state) {
          state.alert = '';
      }
  },
  actions: {
  },
  modules: {
  }
})
