// Axios请求头中的Content-Type常见的有3种：
// 1.Content-Type:application/json   Axios中默认的请求类型，它声明了请求数据会以json字符串的形式发送。在php中，使用$_POST是接收不到的
// 2.Content-Type:application/x-www-form-urlencoded
// 3.Content-Type:multipart/form-data
// 由于第一种类型php接收不到，所以我们要使用Axios自带的qs.stringify()对参数进行序列化，序列化后Content-Type就变成了application/x-www-form-urlencoded，它声明了请求数据会以键值对（普通表单形式）的形式发送，
// 本文件设置请求拦截，在发送post请求时，通过qs序列化，Content-Type就会自动变成application/x-www-form-urlencoded了
// 参考资料：https://blog.csdn.net/lhjuejiang/article/details/108705855

import Vue from 'vue';
import axios from 'axios';
import qs from 'qs';//引入qs，axios在post参数时，要通过qs来格式化数据

// 添加请求拦截器
axios.interceptors.request.use(
    config => {
        // 以下两种判断方式任选
        if (config.headers['Content-Type'] == 'multipart/form-data') {
            // if (Reflect.getPrototypeOf(config.data).constructor.name == 'FormData') {
            // 请求类型 Content-Type: multipart/form-data
        } else if (config.method == 'post') {
            config.data = qs.stringify(config.data);
            // 经过qs.stringify序列化后，请求类型变成了application/x-www-form-urlencoded
        }
        return config;
    },
    error => {
        return Promise.reject(error);
    }
);

// 添加响应拦截器
axios.interceptors.response.use(
    response => {
        return response;
    },
    error => {
        return Promise.reject(error);
    }
);

// 绑定axios为Vue全局变量
Vue.prototype.$axios = axios;
