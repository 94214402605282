import Vue from 'vue'
import VueRouter from 'vue-router'
import global from './../global'  // 引入通用参数及函数库

Vue.use(VueRouter)

const routes = [
    {
        path: '/',  // 网站首页
        name: 'Home',
        component: () => import('../views/home.vue')
    },
    {
        path: '/event',  // 资讯
        name: 'event',
        component: () => import('../views/event.vue')
    },
    {
        path: '/event_show/:id',  // 资讯（查看）
        name: 'event_show',
        component: () => import('../views/event_show.vue')
    },
    {
        path: '/dance_wear',  // 产品 - 舞蹈服装
        name: 'dance_wear',
        component: () => import('../views/dance_wear.vue')
    },
    {
        path: '/photography',  // 产品 - 芭蕾摄影
        name: 'photography',
        component: () => import('../views/photography.vue')
    },
    {
        path: '/photography_show/:id',  // 产品 - 芭蕾摄影（查看）
        name: 'photography_show',
        component: () => import('../views/photography_show.vue')
    },
    {
        path: '/about_mitone',  // 培训 - 关于觅堂
        name: 'about_mitone',
        component: () => import('../views/about_mitone.vue')
    },
    {
        path: '/course_arrangement',  // 培训 - 课程安排
        name: 'course_arrangement',
        component: () => import('../views/course_arrangement.vue')
    },
    {
        path: '/our_teachers',  // 培训 - 师资团队
        name: 'our_teachers',
        component: () => import('../views/our_teachers.vue')
    },
    {
        path: '/class_story',  // 培训 - 课堂花絮
        name: 'class_story',
        component: () => import('../views/class_story.vue')
    },
    {
        path: '/class_story_show/:id',  // 培训 - 课堂花絮（查看）
        name: 'class_story_show',
        component: () => import('../views/class_story_show.vue')
    },
    {
        path: '/about_us',  // 关于我们
        name: 'about_us',
        component: () => import('../views/about_us.vue')
    }
]

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes
})

// 导航守卫
router.beforeEach((to, from, next) => {
    // 网页标题
    if (to.meta.title) {
        document.title = to.meta.title;
    } else {
        document.title = "觅丹思";
    }

    // 判断是否从移动端访问
    if (typeof window !== 'undefined' && typeof window.navigator !== 'undefined') {
        if (!/Android|webOS|iPhone|iPod|BlackBerry/i.test(navigator.userAgent)) {
            //电脑端访问，则直接放行
            next();
        } else {
            window.location.href = global.config.serverWebMobile;
        }
    }
});

export default router
