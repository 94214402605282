<template>
    <div id="alert-animate">{{ alert }}</div>
</template>

<style scoped>
    /* 动画提示 */
    #alert-animate { position:fixed; top:50%; width:auto; height:48px; line-height:48px; margin-top:-24px; padding:0 25px; border-radius:3px; color:#fff; background-color:rgba(0,0,0,0.6); display:none; opacity:0; z-index:999999; }
</style>

<script>
    export default {
        computed: {
            alert () {
                return this.$store.state.alert;
            }
        },
        watch: {
            alert: function() {
                if (this.alert!='') {
                    this.show();
                }
            }
        },
        methods: {
            show: function() {
                let obj = document.getElementById('alert-animate');
                if (obj.innerHTML) {
                    let windowWidth = window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;
                    obj.style.display = 'block';
                    obj.style.left = ((windowWidth - obj.offsetWidth) / 2) + 'px';
                    if (obj.classList.contains('lightSpeedOut')) {
                        obj.classList.remove('lightSpeedOut');
                    }
                    if (!obj.classList.contains('animated')) {
                        obj.classList.add('animated');
                    }
                    obj.classList.add('lightSpeedIn');

                    setTimeout(this.hide, 3000);
                }else{
                    setTimeout(this.show, 100);  // 提示内容尚未生成，则循环调用
                }
            },
            hide: function() {
                let obj = document.getElementById('alert-animate');
                if (obj.classList.contains('lightSpeedIn')) {
                    obj.classList.remove('lightSpeedIn');
                }
                if (!obj.classList.contains('animated')) {
                    obj.classList.add('animated');
                }
                obj.classList.add('lightSpeedOut');

                let that = this;
                setTimeout(function() {
                    obj.style.display = 'none';
                    that.$store.commit('emptyAlert');
                }, 1000);
            }
        }
    }
</script>
